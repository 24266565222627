/* ========================================= 
   THEMING OF BOOTSTRAP COMPONENTS           
   ========================================= */


/*
 * 1. NAVBAR
 */

.navbar {
    padding: $navbar-padding-y $navbar-padding-x;
}

.navbar-brand {
    display: inline-block;
    padding-top: $navbar-brand-padding-y;
    padding-bottom: $navbar-brand-padding-y;
    margin-right: $navbar-padding-x;
    font-size: $navbar-brand-font-size;
}

.navbar-toggler {
    padding: $navbar-toggler-padding-y $navbar-toggler-padding-x;
    font-size: $navbar-toggler-font-size;
    line-height: 1;
    border: $border-width solid transparent; // remove default button style
    @include border-radius($navbar-toggler-border-radius);
}

// Dark links against a light background
.navbar-light {
    .navbar-brand {
        color: $navbar-light-active-color;
        @include hover-focus {
            color: $navbar-light-active-color;
        }
    }
    .navbar-nav {
        .nav-link {
            color: $navbar-light-color;
            @include hover-focus {
                color: $navbar-light-hover-color;
            }
            &.disabled {
                color: $navbar-light-disabled-color;
            }
        }
        .show>.nav-link,
        .active>.nav-link,
        .nav-link.show,
        .nav-link.active {
            color: $navbar-light-active-color;
        }
    }
    .navbar-toggler {
        color: $navbar-light-color;
        border-color: $navbar-light-toggler-border-color;
    }
    .navbar-toggler-icon {
        background-image: $navbar-light-toggler-icon-bg;
    }
    .navbar-text {
        color: $navbar-light-color;
    }
}

// White links against a dark background
.navbar-dark {
    .navbar-brand {
        color: $navbar-dark-active-color;
        @include hover-focus {
            color: $navbar-dark-active-color;
        }
    }
    .navbar-nav {
        .nav-link {
            color: $navbar-dark-color;
            @include hover-focus {
                color: $navbar-dark-hover-color;
            }
            &.disabled {
                color: $navbar-dark-disabled-color;
            }
        }
        .show>.nav-link,
        .active>.nav-link,
        .nav-link.show,
        .nav-link.active {
            color: $navbar-dark-active-color;
        }
    }
    .navbar-toggler {
        color: $navbar-dark-color;
        border-color: $navbar-dark-toggler-border-color;
    }
    .navbar-toggler-icon {
        background-image: $navbar-dark-toggler-icon-bg;
    }
    .navbar-text {
        color: $navbar-dark-color;
    }
}


/*
 * 2. BUTTONS
 */

.btn {
    font-weight: $btn-font-weight;
    border: $input-btn-border-width solid transparent;
    @include button-size($input-btn-padding-y, $input-btn-padding-x, $font-size-base, $input-btn-line-height, $btn-border-radius);
    @include transition($btn-transition);
    &:focus,
    &.focus {
      outline: 0;
      box-shadow: $input-btn-focus-box-shadow;
    }
    &.disabled,
    &:disabled {
      opacity: .65;
      @include box-shadow(none);
    }
  
    &:not([disabled]):not(.disabled):active,
    &:not([disabled]):not(.disabled).active {
      background-image: none;
      @include box-shadow($input-btn-focus-box-shadow, $btn-active-box-shadow);
    }
}

//
// Alternate buttons
//
@each $color,
$value in $theme-colors {
    .btn-#{$color} {
        @include button-variant($value, $value);
    }
}

@each $color,
$value in $theme-colors {
    .btn-outline-#{$color} {
        @include button-outline-variant($value, #fff);
    }
}

//
// Button Sizes
//
.btn-lg {
    @include button-size($input-btn-padding-y-lg, $input-btn-padding-x-lg, $font-size-lg, $line-height-lg, $btn-border-radius-lg);
}

.btn-sm {
    @include button-size($input-btn-padding-y-sm, $input-btn-padding-x-sm, $font-size-sm, $line-height-sm, $btn-border-radius-sm);
}


/*
 * 3. TYPE  
 */

body {
    font-family: $font-family-base;
    font-size: $font-size-base;
    font-weight: $font-weight-base;
    line-height: $line-height-base;
    color: $body-color;
    background-color: $body-bg;
}

a {
    color: $link-color;
    text-decoration: $link-decoration;
    @include hover-focus {
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
    }
}

//
// Headings
//
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: $headings-margin-bottom;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    color: $headings-color;
}

h1,
.h1 {
    font-size: $h1-font-size;
}

h2,
.h2 {
    font-size: $h2-font-size;
}

h3,
.h3 {
    font-size: $h3-font-size;
}

h4,
.h4 {
    font-size: $h4-font-size;
}

h5,
.h5 {
    font-size: $h5-font-size;
}

h6,
.h6 {
    font-size: $h6-font-size;
}

.lead {
    font-size: $lead-font-size;
    font-weight: $lead-font-weight;
}

// Type display classes
.display-1 {
    font-size: $display1-size;
    font-weight: $display1-weight;
    line-height: $display-line-height;
}

.display-2 {
    font-size: $display2-size;
    font-weight: $display2-weight;
    line-height: $display-line-height;
}

.display-3 {
    font-size: $display3-size;
    font-weight: $display3-weight;
    line-height: $display-line-height;
}

.display-4 {
    font-size: $display4-size;
    font-weight: $display4-weight;
    line-height: $display-line-height;
}

//
// Horizontal rules
//
hr {
    border-top: $hr-border-width solid $hr-border-color;
}

//
// Emphasis
//
small,
.small {
    font-size: $small-font-size;
    font-weight: $font-weight-normal;
}

mark,
.mark {
    padding: $mark-padding;
    background-color: $mark-bg;
}

// Blockquotes
.blockquote {
    padding: ($spacer / 2) $spacer;
    margin-bottom: $spacer * 2;
    font-size: $blockquote-font-size;
    border-left: 5px solid theme-color();
    font-weight: 300;
}

.blockquote-footer {
    color: $blockquote-small-color;
    &::before {
        content: "\2014 \00A0"; // em dash, nbsp
    }
}
@include text-emphasis-variant('.text-primary', $brand-primary);

/*
 * 4. PAGINATION
 */

.page-item {
    &:first-child {
        .page-link {
            @include border-left-radius($border-radius);
        }
    }
    &:last-child {
        .page-link {
            @include border-right-radius($border-radius);
        }
    }
    &.active .page-link {
        color: $pagination-active-color;
        background-color: $pagination-active-bg;
        border-color: $pagination-active-border-color;
    }
    &.disabled .page-link {
        color: $pagination-disabled-color;
        background-color: $pagination-disabled-bg;
        border-color: $pagination-disabled-border-color;
    }
}

.page-link {
    padding: $pagination-padding-y $pagination-padding-x;
    line-height: $pagination-line-height;
    color: $pagination-color;
    background-color: $pagination-bg;
    border: $pagination-border-width solid $pagination-border-color;
    @include hover-focus {
        color: $pagination-hover-color;
        text-decoration: none;
        background-color: $pagination-hover-bg;
        border-color: $pagination-hover-border-color;
    }
}

//
// Sizing
//
.pagination-lg {
    @include pagination-size($pagination-padding-y-lg, $pagination-padding-x-lg, $font-size-lg, $line-height-lg, $border-radius-lg);
}

.pagination-sm {
    @include pagination-size($pagination-padding-y-sm, $pagination-padding-x-sm, $font-size-sm, $line-height-sm, $border-radius-sm);
}

/*
* 5. UTILITIES
*/

@each $color,
$value in $theme-colors {
    @include bg-variant('.bg-#{$color}', $value);
}

@each $color,
$value in $theme-colors {
    .border-#{$color} {
        border-color: $value !important;
    }
}

@each $color,
$value in $theme-colors {
    @include text-emphasis-variant('.text-#{$color}', $value);
}

@each $color, $value in $theme-colors {
    .badge-#{$color} {
      @include badge-variant($value);
    }
  }