
/*
=========================================
=========================================

Hub - Bootstrap 4 Ecommerce Template

=========================================
========================================= */


// functions.scss - Bootstrap functions
@import "modules/functions";
// variables-custom.scss - to change colour variant, rounded corners, etc.
@import "modules/variables-custom";
// bootstrap mixins
@import "modules/mixins";
// custom mixins
@import "modules/mixins-custom";
// general styling
@import "modules/general";
// product details page styling
@import "modules/product-details";
// product categories page styling
@import "modules/product-categories";
// cart page styling
@import "modules/cart";
// checkout page styling
@import "modules/checkout";
// contact page styling
@import "modules/contact";
// blog styling
@import "modules/blog";
// about us styling
@import "modules/about";
// customer section
@import "modules/customer";
// demo styling
@import "modules/demo";

// bootstrap base stylesheet overrides
@import "theme";
