/*
* ===================================================
*     PRODUCT DETAILS SECTION
* ===================================================
*/
section.product-details {
    .product-images {
        position: relative;

        .ribbon {
            position: absolute;
            top: 20px;
            left: 3px;
            z-index: 999;

            & + .ribbon {
                top: 80px;

                & + .ribbon {
                    top: 145px;
                }
            }
        }

        .owl-carousel {
            background: #f5f5f5;
            padding: 35px 0;
            overflow: hidden;
        }
    }

    .price {
        font-size: 1.25em;
        .original {
            font-weight: 300;
            color: #aaa;
            text-decoration: line-through;
        }
        .current {
            font-weight: 700;
            color: $brand-primary;
        }
    }

    h2 {
        margin-bottom: 15px;
    }

    p {
        font-weight: 300;
        line-height: 1.7em;
        color: #777;
        margin-top: 20px;
    }

    .CTAs {
        margin-top: 30px;
        a {
            margin-bottom: 10px;
        }
    }
    .quantity {
        margin-right: 20px;
    }
    [class*="ribbon-"] {
        margin-bottom: 20px;
    }

    .item {
        img {
            max-width: 350px;
            margin: 0 auto;
        }
    }

    .owl-thumbs {
        margin-top: 20px;
    }

    .owl-thumb-item{
        background: none;
        border: none;
        opacity: 0.5;
        cursor: pointer;
        margin: 10px;
        outline: none;

        &.active {
            opacity: 1;
        }

        img {
            max-width: 50px;
        }
    }


    ul.rate {
        margin-bottom: 0;
        margin-right: 10px;

        li {
            margin: 0;
            font-size: 0.95em;
        }
    }

    .review {
        span {
            text-transform: uppercase;
            font-size: 0.8em;
            font-weight: 500;
        }
    }
}

/* Product Details Media Query ---------------------------- */
@media (max-width: 992px) {
    section.product-details {
        text-align: center;

        .details {
            margin-top: 50px;
        }
    }
}



/*
* ===================================================
*     PRODUCT DESCRIPTION SECTION
* ===================================================
*/
section.product-description {
    .tab-content {
        padding: 20px 0;
    }
    .tab-pane {
        p {
            color: #666;
            line-height: 1.8em;
            font-weight: 400;
        }
    }
    a.nav-link {
        color: #555;
        text-transform: uppercase;
        border-color: #fff #fff #ddd;
        font-size: 0.9em;
        padding-left: 0;
        padding-right: 20px;
        font-weight: 500;


        &.active, &:hover, &:focus {
            color: $brand-primary;
            border-color: #fff #fff $brand-primary;
        }
    }

    ul.additional-information {
        display: inline-block;

        li {
            padding: 15px;

            &:nth-of-type(even) {
                background: #fafafa;
            }
        }

        strong, span {
            min-width: 150px;
            text-transform: uppercase;
            font-size: 0.85em;
        }

        span {
            color: #656565;
        }
    }

    .share-product {
        padding: 30px 0;
        margin-top: 60px;

        ul {
            margin: 0;
        }

        a {
            width: 35px;
            height: 35px;
            line-height: 35px;
            border-radius: 50%;
            border: 1px solid #bbb;
            color: #777;
            font-size: 0.9em;

            &:hover {
                color: #fff;
                background: $brand-primary;
                border-color: $brand-primary;
            }
        }
    }

    strong {
        color: #666;
        margin-right: 20px;
    }
}


/* Product Description Media Query */
@media (max-width: 768px) {
    section.product-description {
        .share-product {
            strong {
                margin-right: 0;
                margin-bottom: 15px;
            }
        }
    }
}
@media (max-width: 380px) {
    section.product-description {
        .nav-item {
            font-size: 0.85em;
        }
    }
}
