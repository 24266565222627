/* =======================================================
       Contact Page
   ======================================================= */

#map {
    height: 400px;
}

.contact-icon {
    height: 5rem;
    font-size: 3rem;
    color: $brand-primary;
}